<template>
  <b-row class="wrap-border">
    <b-col>
      <b-card>

        <b-card-header>
          <b-card-title>
            搜索
          </b-card-title>
        </b-card-header>

        <b-card-body>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="stock_type_name"
                  variant="outline-primary">
                <b-dropdown-item @click="changeType('00','综合')">
                  综合
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('01','沪市A股')">
                  沪市A股
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('02','深市A股')">
                  深市A股
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('03','沪市B股')">
                  沪市B股
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('04','深市B股')">
                  深市B股
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('05','科创板')">
                  科创板
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('06','创业板')">
                  创业板
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('07','指数')">
                  指数
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('08','基金')">
                  基金
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('09','ETF')">
                  ETF
                </b-dropdown-item>
                <b-dropdown-item @click="changeType('10','债券')">
                  债券
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input v-model="search_key" placeholder="请输入股票代码或股票名（不填则按照分类全库搜索）"/>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="doSearch">
                搜索
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-card-body>
        <b-card-body>
          <b-table
              :fields="fields"
              :items="items">
            <template #cell(name)="data">
              <a :href="`/stock/`+data.item.code">{{ data.item.name }}</a>
            </template>
          </b-table>
        </b-card-body>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BCardText,
  BMedia,
  BImg,
  BOverlay,
  BButton,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BTable
} from 'bootstrap-vue'
import StockIndexCard from "@/views/Home/StockIndexCard";
import HotDiscussionCard from "@/views/Home/HotDiscussionCard";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardText,
    BMedia,
    BImg,
    BOverlay,
    StockIndexCard,
    HotDiscussionCard,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BTable
  },
  data() {
    return {
      stock_type_code: "00",
      stock_type_name: "全部",
      search_key: '',
      fields: [
        {
          key: 'name',
          label: '股票名',
        },
        {
          key: 'type',
          label: '股票类型',
        },
        {
          key: 'market',
          label: '交易市场',
        },
        {
          key: 'region',
          label: '发行地区',
        },
        {
          key: 'currency',
          label: '货币类型',
        },
      ],
      items: [
      ],
    }
  },
  methods: {
    changeType(type_code, type_name) {
      this.stock_type_code = type_code
      this.stock_type_name = type_name
    },
    doSearch() {
      this.$http.post("/api/system/stock/search", {
        'type': this.stock_type_code,
        'key': this.search_key,
      }).then(res=>{
        if (res.data.code===200){
          this.items=res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>